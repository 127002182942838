<template>
  <div class="admin-general v-step-general-section">
    <div class="title">
      <h1>{{ $t('admin.general.title') }}</h1>
    </div>
    <div class="content">
      <div class="image">
        <div class="left">
          <svg v-if="!community.logo" />
          <img v-if="community.logo" :src="community.logo" alt="community logo" />
          <AvatarCropper
            trigger="#pick-logo"
            :labels="{ submit: 'Submit', cancel: 'Cancel' }"
            :mimes="'image/png, image/jpeg'"
            :upload-handler="handleUpload"
          />
        </div>
        <div class="right">
          <div class="buttons">
            <div class="upload">
              <button class="primary-full" :class="{ spinner: isLogoLoading }" id="pick-logo">
                {{ $t('admin.general.upload-photo') }}
              </button>
            </div>
            <div class="delete">
              <button
                class="orange"
                v-if="community.logo"
                :class="{ spinner: isLogoDeleteLoading }"
                @click="deleteLogo"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <div class="text">
            <p>
              {{ $t('admin.general.image-type-allowed-and-size') }}
              <br />
              {{ $t('admin.general.image-cache-disclamer') }}
            </p>
          </div>
        </div>
      </div>
      <div class="community-name">
        <label for="community-name">{{ $t('admin.general.community-name') }}</label>
        <div class="custom-input primary">
          <CustomInput
            type="text"
            inputClasses="box col-all-12"
            name="communityName"
            :placeholder="$t('admin.general.community-name_placeholder')"
            v-model="$v.community.name.$model"
            :model="$v.community.name"
            :error="fieldsErrors.name"
          />
        </div>
      </div>
      <div class="api-key">
        <label>{{ $t('admin.general.your-api-key') }}</label>
        <div class="content">
          <div
            class="copy-text col-all-12 v-step-copy-access-code"
            :uk-tooltip="'title:' + $t('admin.general.click-to-copy') + ';' + 'pos: bottom;'"
            @click="copyApiKey"
          >
            <h2 class="col-pc-auto col-mobile-auto">
              {{ apiKey }}
            </h2>
            <i class="fas fa-copy copy-icon col-pc-2 col-mobile-2"></i>
          </div>
          <button
            class="primary regenerate-button col-mdmobile-fit col-smobile-fit"
            :disabled="isApiKeyLoading"
            @click="resetApiKey"
          >
            <i class="fas fa-sync" style="vertical-align: middle;" :class="{ 'fa-spin': isApiKeyLoading }"></i>
          </button>
        </div>
      </div>
      <div class="save-button">
        <button class="orange-full" :class="{ spinner: isLoading }" @click="save">
          {{ $t('profile.general.save-changes') }}
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import { required } from 'vuelidate/lib/validators'
import validationMessage from '@/validationMapping'
import AvatarCropper from 'vue-avatar-cropper'

export default {
  name: 'general',
  components: {
    AvatarCropper
  },
  data() {
    return {
      isLoading: false,
      isApiKeyLoading: false,
      isLogoLoading: false,
      isLogoDeleteLoading: false,
      fieldsErrors: {},
      community: {
        name: this.$store.state.selection.community.community.name,
        logo: this.$store.state.selection.community.community.logo
      },
      apiKey: '',
      signedRequest: {}
    }
  },
  validations: {
    community: {
      name: {
        required
      }
    }
  },
  apollo: {
    apiKey: {
      query: require('@/graphql/admin/fetchApiKey.gql'),
      update: function(data) {
        return data.currentCommunity.apiKey
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToApiKey.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
          return {
            currentCommunity: {
              __typename: 'Community',
              apiKey: subscriptionData.data.community.node.apiKey
            }
          }
        }
      }
    }
  },
  methods: {
    save() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          name: validationMessage(this.$v.community.name)
        }

        this.isLoading = false
        return
      }

      this.$store
        .dispatch('admin/updateCurrentCommunity', { name: this.community.name })
        .then(() => {
          this.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })

            this.fieldsErrors = {
              name: true
            }
          })
        })
    },
    handleUpload(cropper) {
      this.isLogoLoading = true

      const _this = this
      const canvas = cropper.getCroppedCanvas()
      canvas.toBlob(
        function(blob) {
          if (blob.size > 800000) {
            _this.$store.dispatch('errorManager/showError', { error: 'FILE_TOO_LARGE' })
            return
          }

          _this.$store.dispatch('admin/getS3SignedUrlForLogo').then(response => {
            fetch(response.signedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'image/png'
              },
              body: new File([blob], 'logo.png')
            })
              .then(response2 => {
                _this.$store.dispatch('admin/updateCurrentCommunity', { logo: response.url }).then(() => {
                  _this.isLogoLoading = false

                  _this.community.logo = _this.$store.state.selection.community.community.logo

                  _this.$notify({
                    type: 'success',
                    title: _this.$t('notifications.save.title'),
                    text: _this.$t('notifications.save.text')
                  })
                })
              })
              .catch(error => {
                _this.$store.dispatch('errorManager/showError', { error: error })
              })
          })
        },
        'image/png',
        0.85
      )
    },
    deleteLogo() {
      this.isLogoDeleteLoading = true

      this.$store.dispatch('admin/updateCurrentCommunity', { logo: 'REMOVED' }).then(() => {
        this.isLogoDeleteLoading = false

        this.community.logo = this.$store.state.selection.community.community.logo

        this.$notify({
          type: 'success',
          title: this.$t('notifications.save.title'),
          text: this.$t('notifications.save.text')
        })
      })
    },
    resetApiKey() {
      this.$swal({
        title: this.$t('admin.general.api-key-refresh-dialog.title'),
        text: this.$t('admin.general.api-key-refresh-dialog.text'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.refresh'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.isApiKeyLoading = true

          this.$store.dispatch('admin/resetApiKey').then(() => {
            this.isApiKeyLoading = false

            this.$notify({
              type: 'success',
              title: this.$t('notifications.api-key-changed.title'),
              text: this.$t('notifications.api-key-changed.text')
            })
          })
        }
      })
    },
    copyApiKey() {
      this.$copyText(this.apiKey).then(() => {
        this.$notify({
          type: 'success',
          title: this.$t('notifications.copy.title'),
          text: this.$t('notifications.copy.text', { text: this.apiKey })
        })
      })
    }
  }
}
</script>
